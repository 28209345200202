import styles from './LandingSsw.module.scss'
import React from 'react'
import cx from 'clsx'

import Container from '../../layouts/Container/Container'
import Button from '../Button/Button'
import ChevronRight from '../icons/ChevronRight'
import Link from '../Link/Link'
import LogomarkIcon from '../icons/LogomarkIcon'

const LandingSsw = ({ title, body, cta, className, ...restProps }) => {
  return (
    <section className={cx(styles.rootWrapper)} {...restProps}>
      <LogomarkIcon className={styles.logo} />
      <Container narrow className={styles.root}>
        <div className={styles.grid}>
          <div>
            <header className={styles.header}>
              <h2
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html: title ? title : 'Apa itu Visa SSW?',
                }}
              />
              <p
                className={styles.subtitle}
                dangerouslySetInnerHTML={{
                  __html: body
                    ? body
                    : `Visa SSW (Specified Skilled Worker) atau Tokutei Ginou
                      (特定技能) adalah visa baru dari pemerintah Jepang untuk
                      pekerja asing dengan keterampilan khusus. <br />
                      Pemegang Visa SSW dijamin memiliki hak kerja yang sama
                      seperti orang Jepang.`,
                }}
              />
            </header>
            <footer className={styles.footer}>
              <Link to="/tentang-visa-ssw">
                <Button primaryText large>
                  <span>{cta ?? 'Selengkapnya'}</span>
                  <ChevronRight className={styles.chevron} />
                </Button>
              </Link>
            </footer>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default LandingSsw
