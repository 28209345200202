import React from 'react'

const PelatihanIllustration = ({ style, className, ...restProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
      viewBox="0 0 1002 1143"
      fill="none"
      {...restProps}
    >
      <path
        d="M133.569 240.336C49.4766 357.318 4.00627 473.945 0.496524 584.489C-3.43951 695.048 34.4856 799.866 84.5599 907.909C134.648 1015.6 197.255 1126.5 281.319 1141.16C365.382 1155.47 470.916 1073.9 586.099 1027.76C701.693 981.961 826.85 971.942 904.889 902.907C982.828 833.502 1013.24 705.067 997.492 579.843C981.393 454.633 918.431 332.578 821.849 218.154C724.897 103.317 593.616 -4.01555 466.98 0.986589C339.99 6.00294 217.291 123.34 133.569 240.336Z"
        fill="#F8F8F8"
      />
      <path
        d="M296.569 702.123C273.902 765.477 264.601 1055.39 264.601 1104.58H381.897L430.719 994.55L345.978 686.481L296.569 702.123Z"
        fill="#4A2525"
      />
      <path
        d="M338.09 710.188C344.491 752.549 401.464 1070.97 414.179 1104.58H583.66C590.862 1080.57 618.067 798.148 583.66 694.146C549.254 590.144 338.09 710.188 338.09 710.188Z"
        fill="#843131"
      />
      <path
        d="M308.697 541.843C297.908 539.718 151.081 472.738 117.825 444.23C84.5685 415.722 124.301 393.704 136.828 401.044C149.355 408.383 279.767 464.535 306.547 472.737C333.327 480.94 308.697 541.843 308.697 541.843Z"
        fill="#DE8E68"
      />
      <path
        d="M83.8674 238.082L190.65 246.412L174.558 452.74L67.7753 444.41L83.8674 238.082Z"
        fill="#DEDEDE"
      />
      <path
        d="M236.459 285.551L180.611 399.118"
        stroke="black"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M456.434 238.583C426.026 235.258 405.287 196.792 410.112 152.668C414.936 108.543 443.497 75.468 473.904 78.793C504.311 82.118 525.05 120.584 520.226 164.708C515.402 208.833 486.841 241.908 456.434 238.583Z"
        fill="#DE8E68"
      />
      <path
        d="M476.14 251.316C291.106 251.316 282.479 702.06 290.606 710.187C298.732 718.315 486.892 756.45 589.411 713.938C614.428 605.16 597.412 251.316 476.14 251.316Z"
        fill="#DC3939"
      />
      <path
        d="M183.348 344.866C183.348 334.313 194.6 312.358 211.115 316.259C222.48 318.934 238.108 341.728 244.872 351.405C267.763 367.172 412.615 486.991 423.755 498.594C424.217 488.917 433.506 469.549 444.658 451.632L538.988 512.635C529.224 539.755 468.363 611.411 444.658 611.411C382.434 611.411 256.624 442.329 224.743 396.98C209.84 386.615 183.335 365.922 183.348 344.866Z"
        fill="#DE8E68"
      />
      <path
        d="M560.205 119.607C560.205 96.1381 541.164 82.4095 534.975 75.9953C522.473 62.9919 514.972 28.3202 488.554 28.3202C481.053 28.3202 469.401 32.6839 462.074 32.6839C452.91 32.6464 434.669 14.5791 416.279 14.5791C385.473 14.5791 378.622 42.9366 378.622 51.6514C378.622 83.5598 418.354 107.641 423.63 110.517C425.868 106.853 436.132 93.1498 439.545 91.0743C443.634 99.6015 471.226 113.793 492.08 126.509C503.757 133.636 511.109 188.95 510.933 193.314C530.412 193.376 560.205 163.818 560.205 119.607Z"
        fill="black"
      />
      <path
        d="M513.997 187.999C505.332 187.999 498.307 180.974 498.307 172.308C498.307 163.642 505.332 156.616 513.997 156.616C522.663 156.616 529.688 163.642 529.688 172.308C529.688 180.974 522.663 187.999 513.997 187.999Z"
        fill="#DE8E68"
      />
      <path
        d="M449.885 281.212C448.634 289.052 497.781 287.064 498.356 278.712L503.77 200.141L459.261 195.277C459.261 195.277 451.06 273.398 449.885 281.212Z"
        fill="#DE8E68"
      />
      <path
        d="M574.058 318.834C629.305 348.929 579.909 505.233 545.015 521.237C505.595 521.237 426.043 464.16 426.043 444.892C426.043 425.625 502.394 279.824 574.058 318.834Z"
        fill="#B52C2C"
      />
      <path
        d="M112.486 330.387C112.486 356.332 81.6431 370.023 77.1548 370.023C67.7531 370.023 67.7531 341.628 67.7531 334.276C67.7531 317.522 84.0936 305.256 96.5083 305.256C108.923 305.256 112.486 315.684 112.486 330.387Z"
        fill="#DE8E68"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M625.011 400.841L622.199 386.641C621.405 382.671 619.068 379.178 615.701 376.929C612.334 374.68 608.213 373.858 604.241 374.645L547.696 385.892C543.726 386.686 540.233 389.023 537.984 392.39C535.735 395.756 534.913 399.878 535.699 403.85L576.939 610.862C577.33 612.829 578.104 614.7 579.219 616.367C580.333 618.035 581.765 619.467 583.432 620.581C585.1 621.695 586.971 622.469 588.938 622.86C590.905 623.251 592.93 623.251 594.896 622.859L632.162 615.436C637.101 614.171 643.596 612.259 643.596 612.259L625.011 400.841Z"
        fill="#16325B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M777.324 1057.79L781.87 1072.06L819.82 1063.34L816.605 1046.28L777.324 1057.79Z"
        fill="#E49D75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M541.096 933.653L527.178 940.299L545.979 973.759L562.325 963.111L541.096 933.653Z"
        fill="#E49D75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M627.194 583.25C627.194 583.25 665.331 783.908 676.344 811.004C661.732 822.87 540.348 933.907 540.348 933.907L560.967 964.143L736.703 863.528L700.572 562.809L627.194 583.25Z"
        fill="#4A2525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M651.89 590.823C660.851 613.196 777.221 1058.1 777.221 1058.1L817.945 1047.36L782.141 557.71C782.141 557.71 642.94 568.451 651.89 590.823Z"
        fill="#843131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M672.631 361.598C762.552 342 794.419 535.328 788.326 559.416C782.234 583.503 640.061 607.216 629.639 592.632C619.217 578.048 577.546 382.321 672.631 361.598Z"
        fill="#DC3939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M648.394 313.197C654.318 328.465 656.558 351.372 658.001 373.482C665.949 380.399 689.465 378.093 692.755 374.007C690.121 367.033 690.777 345.448 692.23 338.738C693.682 332.027 645.086 304.678 648.394 313.197Z"
        fill="#E8BFA8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M793.949 344.866L822.451 235.254L835.198 238.562L806.696 348.222L793.949 344.866Z"
        fill="#DFDFDF"
      />
      <path
        d="M801.703 334.539L809.004 336.414"
        stroke="white"
        strokeWidth="1.01"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M805.449 320.125L812.75 322"
        stroke="white"
        strokeWidth="1.01"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M809.189 305.709L816.5 307.584"
        stroke="white"
        strokeWidth="1.01"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M812.938 291.294L820.248 293.168"
        stroke="white"
        strokeWidth="1.01"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M816.688 276.879L823.989 278.753"
        stroke="white"
        strokeWidth="1.01"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M820.438 262.464L827.739 264.338"
        stroke="white"
        strokeWidth="1.01"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M824.184 248.05L831.485 249.924"
        stroke="white"
        strokeWidth="1.01"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M836.528 303.3C844.138 300.62 849.312 307.659 851.393 313.92C854.729 323.986 852.517 333.171 849.312 334.539C847.53 335.332 845.534 335.499 843.645 335.014C841.757 334.529 840.088 333.421 838.908 331.868C838.683 334.614 838.29 336.901 833.285 339.076C825.787 342.328 817.501 332.515 815.542 327.454C812.937 320.799 814.165 310.864 819.76 308.465C824.371 306.487 827.521 307.912 828.973 309.73C831.026 306.497 833.332 304.425 836.528 303.3Z"
        fill="#C93333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M831.684 283.056C842.697 283.993 834.421 301.417 829.228 305.128C825.554 302.026 821.027 282.146 831.684 283.056Z"
        fill="#4A9A87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M632.995 488.709C632.208 505.27 627.793 549.677 661.028 557.363C692.642 564.683 796.556 494.238 815.217 490.039C833.878 485.841 834.187 492.476 847.73 487.396C874.433 477.368 869.287 466.364 867.178 455.07C855.875 458.744 695.144 498.184 689.718 499.609C687.402 494.467 686.149 488.91 686.034 483.272C686.034 483.272 633.782 472.147 632.995 488.709Z"
        fill="#FDD5BF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M653.099 397.158C697.863 394.984 701.087 486.835 694.508 492.862C687.928 498.888 625.713 503.284 621.026 496.433C616.34 489.581 613.613 399.079 653.099 397.158Z"
        fill="#B52C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M748.847 450.984C738.256 453.646 731.479 462.981 733.71 471.828C735.941 480.676 746.325 485.728 756.926 483.019L872.377 453.964L864.317 421.929L748.847 450.984Z"
        fill="#3B402E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M747.236 428.002L910.694 386.679L916.318 408.967L752.86 450.281L747.236 428.002Z"
        fill="#DFDFDF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M743.066 389.828L861.377 359.92L870.805 397.213L752.486 427.121L743.066 389.828Z"
        fill="#874343"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M703.102 376.332L866.588 335.008L872.211 357.324L708.753 398.638L703.102 376.332Z"
        fill="#3B402E"
      />
      <path
        d="M751.473 454.246L757.331 477.865"
        stroke="#6BD5E1"
        strokeWidth="1.36"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M755.738 400.822L789.123 392.387L791.785 402.912L758.4 411.347L755.738 400.822Z"
        fill="#DFDFDF"
      />
      <path
        d="M714.846 385.048L782.235 367.793"
        stroke="#FFD98E"
        strokeWidth="2.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M496.803 955.586L550.358 1054.74L565.457 1046.77L548.633 973.665L527.301 939.999L496.803 955.586Z"
        fill="#2B2B2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M786.582 1104.92L897.535 1085.24L894.723 1068.37L820.005 1061.35L781.361 1071.03L786.582 1104.92Z"
        fill="#2B2B2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M713.899 243.503C709.672 220.484 687.046 210.587 667.054 214.908C632.292 222.406 630.136 209.813 594.745 244.647C576.496 266.803 563.926 258.086 530.869 266.803C486.068 278.612 479.404 310.601 485.881 339.3C496.668 386.772 537.505 397.41 568.125 392.93C612.739 386.369 590.088 362.925 608.538 346.672C619.921 336.644 633.032 330.734 646.332 328.232C659.631 325.729 681.216 308.484 687.571 301.913C693.926 295.343 716.411 257.169 713.899 243.503Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M642.949 294.602C638.263 266.822 651.244 242.182 671.948 239.567C692.652 236.952 713.253 257.356 717.939 285.155C722.625 312.954 709.644 337.576 688.94 340.191C668.236 342.806 647.635 322.383 642.949 294.602Z"
        fill="#FDD5BF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M705.379 250.477C708.8 268.697 655.76 298.043 639.077 301.726C622.394 305.41 616.039 271.003 616.039 271.003C616.039 271.003 672.471 204.898 705.379 250.477Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M637.418 309.13C637.416 307.158 637.999 305.231 639.093 303.591C640.187 301.951 641.743 300.673 643.564 299.917C645.385 299.162 647.389 298.964 649.322 299.348C651.256 299.732 653.032 300.681 654.426 302.075C655.82 303.469 656.769 305.245 657.153 307.179C657.537 309.112 657.339 311.116 656.584 312.937C655.829 314.758 654.55 316.314 652.91 317.408C651.271 318.502 649.343 319.085 647.372 319.083C644.732 319.083 642.2 318.035 640.333 316.168C638.467 314.301 637.418 311.77 637.418 309.13V309.13Z"
        fill="#FDD5BF"
      />
      <path
        d="M619.152 392.283C623.961 388.279 629.654 385.478 635.761 384.11"
        stroke="#16325B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M635.799 384.11C665.173 377.662 696.599 407.073 705.981 449.812C709.73 466.749 709.402 483.235 705.859 497.369"
        stroke="#16325B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PelatihanIllustration
