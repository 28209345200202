import styles from "./LandingScheduleSsw.module.scss"
import React, { useState } from "react"
import cx from "clsx"
import Container from "../../layouts/Container/Container"
import Text from "../Text/Text"
import ArrowRightIcon from "../icons/ArrowRightIcon"
import Button from "../Button/Button"
import Link from "../Link/Link"

import { useQuery } from "react-query"

import { fetchSchedulesContent } from "../../utils/api"

const Ujian = ({ title, industry, occupation, date, place, url }) => {
  return (
    <li className={styles.ujianWrapper}>
      <article className={styles.ujian}>
        <header className={styles.infoHeader}>
          <div className={styles.text}>
            <p className={styles.place}>{place}</p>
            <Text as="h3" heading4 className={styles.title}>
              {industry?.label}
            </Text>
            <Text heading6 as="p" secondary>
              Sub-bidang: {occupation}
            </Text>
          </div>
          <a href={url} target="_blank" className={styles.link}>
            <ArrowRightIcon className={styles.arrow} />
          </a>
        </header>
        <Text heading6 as="p" secondary>
          {date}
        </Text>
      </article>
    </li>
  )
}

const LandingScheduleSsw = ({ children, className, ...restProps }) => {
  const limit = 3
  const { data: schedules, isLoading: loading } = useQuery(
    ["content-landing-schedules"],
    fetchSchedulesContent
  )

  return (
    <section className={cx(styles.root)} {...restProps}>
      <Container narrow>
        <header className={styles.header}>
          <Text heading3 as="h2" className={styles.title}>
            Jadwal ujian SSW
          </Text>
        </header>
        <ul className={styles.listWrapper}>
          {loading
            ? "Loading..."
            : schedules?.length > 0
            ? schedules
                ?.slice(0, limit)
                .map((s) => (
                  <Ujian
                    key={`ujian-ssw-${s?.id}`}
                    title={s?.title?.rendered}
                    industry={s?.acf?.industry}
                    occupation={s?.acf?.occupation}
                    date={s?.acf?.date}
                    place={s?.acf?.place}
                    url={s?.acf?.url}
                  />
                ))
            : "Belum ada jadwal ujian"}
        </ul>
        {limit < schedules?.length && (
          <footer className={styles.footer}>
            <Link to="/info-ujian-ssw">
              <Button darkGhost alt>
                Info Ujian SSW Selengkapnya
              </Button>
            </Link>
          </footer>
        )}
      </Container>
    </section>
  )
}

export default LandingScheduleSsw
