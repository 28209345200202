import styles from './LandingPelatihan.module.scss'
import React from 'react'
import cx from 'clsx'
// import Image from "next/image"

import PelatihanIllustration from './PelatihanIllustration'

import Container from '../../layouts/Container/Container'
import Text from '../Text/Text'
import Button from '../Button/Button'
import ChevronRight from '../icons/ChevronRight'
import Link from '../Link/Link'

const LandingPelatihan = ({ title, body, cta, className, ...restProps }) => {
  return (
    <section className={cx(styles.root)} {...restProps}>
      <Container narrow>
        <div className={styles.box}>
          <PelatihanIllustration className={styles.illustration} />
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: title ? title : 'Kamu belum punya sertifikat untuk SSW?',
            }}
          ></h2>
          <p
            className={styles.body}
            dangerouslySetInnerHTML={{ __html: body ? body : '' }}
          />
          {cta && (
            <Link to="/pelatihan-kerja" className={styles.ctaWrapper}>
              <Button primary large className={styles.cta}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: cta ? cta : 'Lihat Selengkapnya',
                  }}
                ></span>
                {/* <ChevronRight className={styles.chevron} /> */}
              </Button>
            </Link>
          )}
          <footer className={styles.footer}>
            <Text as="p" heading6 secondary>
              Bekerja sama dengan
            </Text>
            <Link external to="http://os-selnajaya.com">
              <img
                className={styles.logo}
                src="https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/jp/logo/os.png"
                width={222}
                height={55}
              />
            </Link>
          </footer>
        </div>
      </Container>
    </section>
  )
}

export default LandingPelatihan
