import styles from "./LandingHero.module.scss"
import React, { useState } from "react"
import cx from "clsx"
import { useRouter } from "next/router"

import ClientWrapper from "../../utils/ClientWrapper"

import Container from "../../layouts/Container/Container"

import {
  usePrefecturesQuery,
  useSswIndustriesQuery,
} from "../../atoms/setupAtom"

import Text from "../Text/Text"
import SearchIcon from "../SearchIcon"

const Searchbox = ClientWrapper(() => {
  const router = useRouter()
  const { data: prefectures } = usePrefecturesQuery()
  const { data: sswIndustries } = useSswIndustriesQuery()

  const [keyword, setKeyword] = useState("")
  const [bidang, setBidang] = useState("")
  const [prefecture, setPrefecture] = useState("")

  const handleSearch = () => {
    let query = {}
    if (prefecture) {
      query.prefecture = prefecture.toLowerCase()
    }
    router.push(
      {
        pathname: "/lowongan",
        query,
      },
      undefined,
      { shallow: true, scroll: true }
    )
  }

  return (
    <div className={styles.searchWrapper}>
      <div className={styles.fieldWrapper}>
        <label htmlFor="search-prefecture" className={styles.label}>
          Bidang SSW
        </label>
        <select
          id="search-bidang-ssw"
          name="search-bidang-ssw"
          className={cx(styles.field, styles.prefecture)}
          value={JSON.stringify(bidang)}
          onChange={(e) => setBidang(JSON.parse(e?.target.value))}
        >
          <option value={JSON.stringify({})}>Semua Bidang</option>
          {sswIndustries?.length > 0 &&
            sswIndustries?.map((ssw) => (
              <option key={ssw?._id} value={JSON.stringify(ssw)}>
                {ssw?.industry_ind}
              </option>
            ))}
        </select>
      </div>
      {/* <div className={styles.fieldWrapper}>
        <label htmlFor="search-keyword" className={styles.label}>
          Keyword
        </label>
        <input
          type="text"
          id="search-keyword"
          name="search-keyword"
          className={cx(styles.field, styles.keyword)}
          placeholder="Judul, nama perusahaan, dll."
          value={keyword}
          onChange={(e) => setKeyword(e?.currentTarget?.value)}
        />
      </div> */}
      <div className={styles.divider} />
      <div className={styles.fieldWrapper}>
        <label htmlFor="search-prefecture" className={styles.label}>
          Prefektur
        </label>
        <select
          id="search-prefecture"
          name="search-prefecture"
          className={cx(styles.field, styles.prefecture)}
          value={prefecture}
          onChange={(e) => setPrefecture(e?.target.value)}
        >
          {prefectures?.length > 0 && (
            <>
              <option>Semua Prefektur</option>
              {prefectures?.map((prefecture) => (
                <option key={prefecture?._id} value={prefecture?.id}>
                  {prefecture?.idn}
                </option>
              ))}
            </>
          )}
        </select>
      </div>
      <button className={styles.buttonWrapper} onClick={handleSearch}>
        <SearchIcon className={styles.searchIcon} />
        <span className={styles.searchText}>
          Cari <span className={styles.lowongan}>Lowongan SSW</span>{" "}
        </span>
      </button>
    </div>
  )
})

const LandingHero = ({ title, subtitle, cover, className, ...restProps }) => {
  // console.log({ title, subtitle, cover })

  return (
    <section
      className={cx(styles.root)}
      style={{
        backgroundImage: `
        linear-gradient( 180deg, rgba(237, 0, 1, 0.2) 10%, rgba(0,0,0,.64)),
        url("${cover}")`,
      }}
      {...restProps}
    >
      <Container narrow>
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: title
              ? title
              : "Ayo, Bekerja di Jepang dengan Visa SSW (Tokutei Ginou)",
          }}
        />
        <p
          className={styles.subtitle}
          dangerouslySetInnerHTML={{
            __html: subtitle ? subtitle : "Terbuka untuk lulusan SMA dan umum",
          }}
        />
        <Searchbox />
      </Container>
    </section>
  )
}

export default LandingHero
