import React from 'react'

const ArrowRightIcon = ({ style, className, ...restProps }) => {
  return (
    <svg
      xmlns="http: //www.w3.org/2000/svg"
      className={className}
      style={{ display: 'block', ...style }}
      viewBox="0 0 24 24"
      fill="none"
      {...restProps}
    >
      <title>Arrow right (filled)</title>
      <path
        d="M22.2 12l-6.5 9h-3.5l5.5-7.5H2v-3h15.7L12.2 3h3.5l6.5 9z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export default ArrowRightIcon
